html {
  overflow: hidden;
}

body {
  padding: 0px;
  margin: 0px;
  background: whitesmoke;
  overflow-y: hidden;

  /* WebKit-based browsers */
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f100;
  }

  ::-webkit-scrollbar-thumb {
    background: #88888800;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #55555500;
  }

  /* Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f100;
  }
}

#content {
  width: 100%;
}

.react-grid-layout {
  background: whitesmoke;
  padding: 0px;
}

.react-grid-item {
  display: flex;
  align-items: row;
  box-sizing: border-box;
}

.react-grid-item:not(.react-grid-placeholder) {
  background: whitesmoke;
}

.react-grid-item.resizing {
  opacity: 0.9;
}
.react-grid-item.static {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.react-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 24px;
}

.react-grid-item .minMax {
  font-size: 12px;
}

.react-grid-item .add {
  cursor: pointer;
}

.react-grid-dragHandleExample {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.toolbox {
  background-color: #dfd;
  width: 100%;
  height: 120px;
  overflow: scroll;
}

.hide-button {
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  top: 0px;
  right: 5px;
}

.toolbox__title {
  font-size: 24px;
  margin-bottom: 5px;
}

.toolbox__items {
  display: block;
}

.toolbox__items__item {
  display: inline-block;
  text-align: center;
  line-height: 40px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  padding: 10px;
  margin: 5px;
  border: 1px solid black;
  background-color: #ddd;
}

.droppable-element {
  width: 150px;
  text-align: center;
  background: #fdd;
  border: 1px solid black;
  margin: 10px 0;
  padding: 10px;
}
