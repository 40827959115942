@keyframes fadeout {
  0% {
    background-color: goldenrod;
    border: "1px solid lightgrey";
  }
  100% {
    background-color: transparent;
    border: "none";
  }
}

.animate {
  animation: fadeout 4s forwards;
}
